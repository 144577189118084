<!--
   /**
      * joinProperty.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">产品定位</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Product positioning</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="30" class="margin_b_10 margin_t_60">
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property01.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">特殊项目型</p>
                            <p class="serviceM">适用项目：智慧军营</p>
                            <p class="serviceM">产品特色：智慧感知、智慧安防、物联网管理、党建政务、智慧办公</p>
                            <p class="serviceM">实施方式：军方项目，根据特殊需求设计，特别实施执行，采用高防御级别的电子围栏、电子哨兵等系统，物料管理系统针对枪械、武器等进行开发布设。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property02.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">政府主导型</p>
                            <p class="serviceM">适用项目：智慧城中村</p>
                            <p class="serviceM">产品特色：党建政务、安防综治、社区商业、便民生活、行政办公</p>
                            <p class="serviceM">实施方式：根据实际情况进行设备与系统的适配，把政务与园区管理打通，引入社区商业运营，提升居民生活质量。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property03.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">物业主导型</p>
                            <p class="serviceM">适用项目：智慧园区、物业社区、写字楼、商场、综合体</p>
                            <p class="serviceM">产品特色：设备管理、人员管理、能耗管理、内控管理、品质管理、财务管理</p>
                            <p class="serviceM">实施方式：适用各种建筑项目，能导入或新建各种原始数据，包括项目基础信息、设备数据、员工数据、财务数据、业主/租户数据等，同时引入商户资源，丰富社区服务的同时为项目带来增值运营。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">产品优势</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Product advantage</p>
                        <p style="text-align: center;padding-top: 10px">为项目打造对人、物、事实现可视、可控、可管的管理平台</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_t_60">
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property04.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">PaaS平台</p>
                            <p class="serviceM">以物联网技术为基础，智能整合项目内的所有设备设施，通过数据反映对人、物、事进行管理，提升社区安全性和用户体验。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property05.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">IOT互通</p>
                            <p class="serviceM">平台支持各种系统的对接，原数据的导入及新建，如物业基础信息、人员信息、业主信息等，为客户提供省时省力的数字化升级，逐步建立实现数字化管理。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property06.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">增值运营</p>
                            <p class="serviceM">面向社区居民的居家生活消费，提供社区互助、社区KOL、周边商户、渠道商家等各种生活服务，为物业创新营收。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="center" :gutter="30" class="margin_b_10">
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property07.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">招商渠道</p>
                            <p class="serviceM">招商过程可监控，掌上招商更轻松，与客户建立线上联系，标准化跟进流程，线上及时了解客户需求并跟进，即时调整招商策略。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p style="text-align: center">
                              <img src="@/assets/property08.png" style="width: 50px;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <p class="serviceS">实力认可</p>
                            <p class="serviceM">目前已经累计引入623家物业项目、12000+的商户与渠道商家，能为客户、用户提供全面的物业管理服务、生活服务。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">应用场景</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">APPLICATION SCENARIO</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_t_60">
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="30">
                          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <p style="text-align: center">
                              <img src="@/assets/changjing_icon05.png" style="width: 100%;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                            <p class="serviceS" style="text-align: left">特殊项目</p>
                            <p class="serviceM">智慧军营、智能监狱等对安防管理、物资管理、人员管理有特殊要求的项目，采用智能感知技术，打造智能安保+物联网管理+智慧OA的全方位智慧管理系统。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="30">
                          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <p style="text-align: center">
                              <img src="@/assets/changjing_icon01.png" style="width: 100%;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                            <p class="serviceS">政府服务</p>
                            <p class="serviceM">共建共治平台助力网格管理高效化，社区综治平台实现大数据可视化，政务服务平台提升公共服务智能化，社区资金管理透明化，不断提高人民满意度。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="30">
                          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <p style="text-align: center">
                              <img src="@/assets/changjing_icon02.png" style="width: 100%;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                            <p class="serviceS">物业服务</p>
                            <p class="serviceM">为物业提供数字化管理及商业运营平台，包括物业基础信息、财务管理、内控管理、KPI管理、员工管理、业主/租户管理、商户管理等，满足工单流程、巡更巡检、自助收缴费、业主互助、商业运营等社区多场景需求。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_40">
                        <el-row :gutter="30">
                          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <p style="text-align: center">
                              <img src="@/assets/changjing_icon03.png" style="width: 100%;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                            <p class="serviceS">商业应用</p>
                            <p class="serviceM">集积分体系、优惠卡券、会员礼品等功能于一体，并引入优质的商品及服务供应商，为用户带来最全面的服务，同时为物业创造营收。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="margin_b_20">
                        <el-row :gutter="30">
                          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <p style="text-align: center">
                              <img src="@/assets/changjing_icon04.png" style="width: 100%;margin-top: 20px">
                            </p>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                            <p class="serviceS">社区综治</p>
                            <p class="serviceM">人脸识别、车牌识别、事件识别，快速准确。小区事务预警、特殊人员预警、人员行动轨迹预警、 纠纷觉察、房屋预警等社区网络化智能预警系统，全方位守护社区安全。</p>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">服务费用</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Service fee</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/fee.png" style="width:100px" /></p>
                        <p class="serviceS colorBlue">每项目每月￥200.00</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">申请流程</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">APPLICATION PROCESS</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/one.png"/></p>
                        <p class="serviceS colorBlue">简单注册</p>
                        <p class="serviceM">填写不超过10项的内容</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/two.png"/></p>
                        <p class="serviceS colorBlue">适用多种物业类型</p>
                        <p class="serviceM">住宅、写字楼、商场、综合体等</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/three.png"/></p>
                        <p class="serviceS colorBlue">导入/新建数据</p>
                        <p class="serviceM">支持原始数据的导入及新建立</p>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="margin_b_20">
                        <p class="serviceP margin_b_20"><img src="@/assets/four.png"/></p>
                        <p class="serviceS colorBlue">放心使用</p>
                        <p class="serviceM">不同企业仅能管理各自的数据、设备、人员，确保数据的保密</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
